import { Box } from "@mui/material";
import { appConstants } from "../assets";

const { gutterMargin, isDesktopDevice } = appConstants;

const DashView = ({ children }) => {
  const isDesktop = isDesktopDevice();
  return isDesktop ? <Box sx={{ padding: `8px 24px 24px 24px` }}>{children}</Box> : <Box>{children}</Box>;
};

export default DashView;
