import React, { useEffect, Suspense } from "react";
import { Box, Grid, Drawer } from "@mui/material";
import shareApproveLogo from "../../../assets/images/midato-shareapprove-logo.jpg";
import { Outlet } from "react-router-dom";
import assets from "../../../assets";
import { useMidatoQuery, useWizard } from "../../../../app/hooks";
import Header from "../Header";
import Sidebar from "../Sidebar";
import DashView from "@midato-mono/common/src/components/DashView";
import { TITLES } from "../../../../Router/constants";
import Spinner from "@midato-mono/common/src/components/Spinner";

const {
  appConstants: { fullGridWidth },
  appStyles: {
    AdminLayoutStyle: {
      conditionalPadding,
      PageContainer,
      DrawerHeader,
      StyledImg,
      ImgContainer,
      paper,
      paperAnchorDockedLeft,
      headerGrid
    }
  }
} = assets;

import { useLocation, matchRoutes } from "react-router-dom";
import { useAuth } from "@midato-mono/common/src";
import { useFetchCurrentUserOrganizationsQuery, useLogUserEventMutation } from "../../../../app/redux/api";
import { getLogUserEventData } from "../../../../app/utils";
import useMidatoMutation from "../../../../app/hooks/useMidatoMutation";

const AdminLayout = () => {
  const location = useLocation();
  const { wizardFunctions } = useWizard();
  const { orgId, setAuth, email } = useAuth();

  const { isLoading, data: organizations } = useMidatoQuery({
    queryFn: useFetchCurrentUserOrganizationsQuery,
    queryArgs: {}
  });

  const { triggerFn: logUserEvent } = useMidatoMutation({ mutationFn: useLogUserEventMutation });

  const saveOrganization = (selectedOrgId?: string) => {
    window.localStorage.setItem("midato:selectedOrganization", selectedOrgId || (orgId as string));
    logUserEvent(
      getLogUserEventData({
        type: "login",
        ownerEmail: email,
        ownerOrgId: selectedOrgId || orgId,
        params: { state: "Success" }
      })
    );
    setAuth({ orgId: selectedOrgId || orgId });
  };

  useEffect(() => {
    if (!window.localStorage.getItem("midato:selectedOrganization") && !isLoading) {
      if (organizations.length === 1) {
        saveOrganization(organizations[0].orgId);
      } else {
        wizardFunctions.launchSelectOrganizationWizard();
      }
    }
  }, [organizations]);
  // const [drawerOpen, setDrawerOpen] = useState(true);

  useEffect(() => {
    let matchedTitle = "";
    Object.keys(TITLES).forEach((route) => {
      const matchedRoutes = matchRoutes([{ path: route }], location.pathname);
      if (matchedRoutes) {
        matchedTitle = TITLES[matchedRoutes[0]?.route?.path];
      }
    });
    document.title = `ConsentLink | ${matchedTitle || ""}`;
  }, [location.pathname]);

  const sxProps = { "& .MuiDrawer-paper": paper, "& .MuiDrawer-paperAnchorDockedLeft": paperAnchorDockedLeft };

  return (
    <PageContainer className="page-container">
      {/* <Drawer variant="persistent" anchor="left" open={drawerOpen} sx={sxProps}> */}
      <Drawer variant="persistent" anchor="left" open={true} sx={sxProps}>
        <DrawerHeader>
          <ImgContainer tabIndex={1} href="/" aria-label="go to home page" style={{ position: "relative" }}>
            <StyledImg aria-label="main logo" image={shareApproveLogo} />
            <div style={{ position: "absolute", top: "52px", fontSize: "13px", left: "50px", color: "#6C7097" }}>
              Powered by Midato Health
            </div>
          </ImgContainer>
        </DrawerHeader>
        <Sidebar />
      </Drawer>
      {/* <Box style={conditionalPadding(drawerOpen)}> */}
      <Box style={conditionalPadding(true)}>
        <Grid container>
          <Grid item xs={fullGridWidth} sx={headerGrid}>
            {/* <Header setDrawerOpen={setDrawerOpen} /> */}
            {orgId && <Header />}
          </Grid>
          <Grid item xs={fullGridWidth}>
            <Suspense fallback={<Spinner />}>
              <DashView>{window.localStorage.getItem("midato:selectedOrganization") && <Outlet />}</DashView>
            </Suspense>
          </Grid>
        </Grid>
      </Box>
    </PageContainer>
  );
};

export default AdminLayout;
