import * as React from "react";
import type { ReactElement } from "react";
import { useEffect } from "react";
import { Box } from "@mui/material";
import BaseField from "./BaseField";
import { useWindowDimensions } from "../../hooks";
import { appConstants } from "../../assets";

const { singleSettingsFieldGroupWidth } = appConstants;

interface BaseFormProps {
  readonly id?: string;
  readonly rows: any;
  readonly formik: any;
  readonly preAmble?: ReactElement;
  readonly postAmble?: ReactElement;
  readonly isViewMode?: boolean;
  readonly customBodyStyles?: { [key: string]: string | number };
  readonly loginType?: string;
}

const { moderatePadding } = appConstants;

const BaseForm = ({
  id,
  preAmble,
  postAmble,
  rows,
  formik,
  isViewMode = false,
  customBodyStyles,
  loginType = "ALL"
}: BaseFormProps) => {
  const { width } = useWindowDimensions();
  const targetWidth = singleSettingsFieldGroupWidth(width);

  useEffect(() => {
    if (formik) {
      rows?.forEach((fields: any, i: any) => {
        fields.forEach((field: any) => {
          if (typeof field.showIf === "function") {
            const showIf = field.showIf(formik);
            if (!showIf) {
              formik.setFieldValue(field.name, undefined);
            }
          }
        });
      });
    }
  }, [formik?.values]);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", height: "100%", width: "100%" }} key={id}>
      {preAmble}
      {(loginType === "ALL" || loginType === "COGNITO") && (
        <Box
          sx={{
            flex: 1,
            overflowY: "auto",
            overflowX: "hidden",
            padding: "0 10px"
          }}
        >
          <div tabIndex={0} style={{ ...customBodyStyles, height: "100%" }}>
            {rows?.map((fields: any, i: any) => {
              return (
                <Box
                  sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap", alignItems: "center" }}
                  key={i}
                  className="form-row"
                >
                  {fields
                    .filter((field: any) => {
                      if (typeof field.showIf === "function") {
                        return field.showIf(formik);
                      } else {
                        return true;
                      }
                    })
                    .map((fieldData: any, i: any) => {
                      const fieldInfo = isViewMode ? { ...fieldData, readOnly: true } : fieldData;
                      fieldInfo.formId = id || "new";
                      const marginRight = fields.length > 1 && i < fields.length - 1 ? `${moderatePadding}px` : "0";
                      const flex = fieldInfo.width
                        ? `0 0  ${fieldInfo.width}`
                        : fieldInfo.short
                          ? `0 0 ${targetWidth}`
                          : `1`;

                      const fieldName = fieldInfo.name;
                      const formikValue = formik.values?.[fieldName] ?? "";
                      const fieldValue = fieldInfo?.value ?? "";
                      const fieldTouched = formik.values?.[fieldName] !== formik.initialValues?.[fieldName];
                      const formikError =
                        (formik.submitCount === 0 && fieldTouched) || formik.submitCount > 0
                          ? formik.errors?.[fieldName]
                          : "";

                      return (
                        <Box
                          sx={{ flex: 1, minWidth: "fit-content" }}
                          className="form-column"
                          key={`${i}${fieldInfo.name}`}
                          style={{ flex, marginRight }}
                        >
                          <BaseField
                            needConfirmation={formik?.values?.needConfirmation}
                            oldFieldValues={formik?.values?.oldFieldValues}
                            value={formikValue ? formikValue : fieldValue}
                            error={formikError}
                            setFieldValue={formik.setFieldValue}
                            fieldInfo={fieldInfo}
                          />
                        </Box>
                      );
                    })}
                </Box>
              );
            })}
          </div>
        </Box>
      )}

      {postAmble}
    </Box>
  );
};

export default BaseForm;
