import { SecurityRoleItemPageConfiguration } from "./types";
import assets from "../../../ui/assets";
import {
  createButton,
  createButtonBoxPostAmble,
  createAction,
  formIsUntouchedOrHasError,
  disableFields
} from "../../utils";
import {
  useAddNewSecurityRoleMutation,
  useGetOrganizationsQuery,
  useUpdateSecurityRoleMutation,
  useFetchSecurityRoleQuery,
  useDeleteSecurityRoleMutation,
  useGetAssignedPermissionsQuery
} from "../../redux/api";
import { useMidatoQuery } from "../../hooks";
import fields from "./fields";
import { routes } from "../../../Router/constants";
import { userCan } from "../../utils";
import { useAuth } from "@midato-mono/common/src";

const {
  appStrings: {
    common: {
      appStrings: {
        buttonText: { save, submit, deactivate, cancel, copy, edit },
        prompts: { deleteConfirmation }
      }
    },
    admin: {
      securityRole: {
        item: { detailPageHeader }
      }
    }
  }
} = assets;

// new
type NC = SecurityRoleItemPageConfiguration["new"];
type DC = SecurityRoleItemPageConfiguration["detail"];

export const createNewActions: NC["createActions"] = ({ toaster }) => ({
  add: createAction({ toaster, mutationFn: useAddNewSecurityRoleMutation, type: "add" })
});

export const createNewEntityName: NC["createEntityName"] = () => "New Security Role";

export const createNewRows: NC["createRows"] = ({ initialQueryData }) => {
  return [
    [fields.name],
    [fields.externalID],
    [
      {
        ...fields.orgId,
        options: initialQueryData?.organizations?.map(({ orgId, name }: { orgId: string; name: string }) => ({
          name: orgId,
          label: name
        }))
      }
    ],
    [fields.active],
    [
      {
        ...fields.permissions,
        options: initialQueryData?.permissions?.map(({ id, name }: { id: string; name: string }) => ({
          name: id,
          label: name
        }))
      }
    ]
  ];
};

export const createNewPostAmble: NC["createPostAmble"] = ({
  formik,
  actions: { add },
  navigate,
  permissions,
  closeWizard
}) => {
  const values = formik.values;
  const params = {
    userRole: {
      name: values?.name ?? "",
      active: values?.active ?? true,
      externalID: values?.externalID ?? "",
      permissions: values?.permissions ?? [],
      ...(values?.orgId ? { orgId: values?.orgId } : {})
    }
  };

  const saveButton = createButton({
    id: "btn-save",
    disabled: formIsUntouchedOrHasError(formik),
    color: "primary",
    onPress: async () => {
      formik.handleSubmit();
      if (formik.isValid) {
        const userRoleId = await add!(params).unwrap();
        closeWizard();
        navigate(`${routes.SECURITY_ROLES}/${userRoleId}`, { replace: true });
      }
    },
    text: submit,
    actionId: "SECURITY_ROLE_ADD"
  });

  const cancelButton = createButton({
    actionId: "SECURITY_ROLE_ADD",
    id: "btn-cancel",
    text: cancel,
    color: "secondary",
    onPress: () => {
      closeWizard();
    },
    variant: "outlined"
  });

  return createButtonBoxPostAmble({
    permissions,
    buttonsMeta: [saveButton, cancelButton]
  });
};

export const useDetailInitialQuery: DC["useInitialQuery"] = ({ params: { id: userRoleId } }) => {
  const { orgId } = useAuth();
  const { data: permissions } = useMidatoQuery({
    queryFn: useGetAssignedPermissionsQuery,
    queryArgs: {}
  });

  const { data: organizations } = useMidatoQuery({
    queryFn: useGetOrganizationsQuery,
    queryArgs: {
      showAll: false
      // params: {
      //   type: "organization",
      //   limit: 100000,
      //   query: "*",
      //   orgId
      // }
    }
  });

  const { data: securityRoleData } = userRoleId
    ? useMidatoQuery({
        queryFn: useFetchSecurityRoleQuery,
        queryArgs: {
          userRoleId
        },
        queryOptions: { skip: !userRoleId }
      })
    : { data: { organization: organizations?.length === 1 ? organizations?.[0]?.orgId : "" } };

  return {
    data: {
      permissions: permissions?.filter(({ active }: { active: boolean }) => active),
      securityRoleData,
      organizations: organizations
        ? [...organizations]?.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
        : []
    }
  };
};

// detail
export const createDetailDocumentTitle: DC["createDocumentTitle"] = ({ initialQueryData: { userData: userRole } }) =>
  userRole?.name ? `${userRole?.name}` : detailPageHeader;

export const createDetailEntityName: DC["createEntityName"] = ({ initialQueryData: { userRoleData: userRole } }) =>
  `${userRole?.name || ""}`;

export const createDetailActions: DC["createActions"] = ({ toaster }) => ({
  update: createAction({ toaster, mutationFn: useUpdateSecurityRoleMutation, type: "update" }),
  delete: createAction({ toaster, mutationFn: useDeleteSecurityRoleMutation, type: "delete" })
});

export const createDetailRows: DC["createRows"] = ({ initialQueryData }) => [
  [fields.name],
  [fields.externalID],
  disableFields([
    {
      ...fields.orgId,
      options: initialQueryData?.organizations?.map(({ orgId, name }: { orgId: string; name: string }) => ({
        name: orgId,
        label: name
      }))
    }
  ]),
  disableFields([fields.active]),
  [
    {
      ...fields.permissions,
      options: initialQueryData?.permissions?.map(({ id, name }: { id: string; name: string }) => ({
        name: id,
        label: name
      }))
    }
  ]
];

export const createDetailInitialValues: DC["createInitialValues"] = ({
  initialQueryData: { securityRoleData: securityRole }
}) => {
  return {
    name: securityRole?.name ?? "",
    active: securityRole?.active ?? true,
    externalID: securityRole?.externalID ?? "",
    orgId: securityRole?.orgId ?? "",
    permissions: securityRole?.permissions ?? []
  };
};

export const createDetailPostAmble: DC["createPostAmble"] = ({
  formik,
  params: { id: userRoleId },
  actions: { update, delete: deleteFn },
  navigate,
  permissions,
  closeWizard
}) => {
  const values = formik.values;
  const params = {
    userRoleId: userRoleId,
    userRole: {
      name: values.name,
      active: values.active,
      externalID: values.externalID,
      permissions: values.permissions
      // TODO
      // orgId: values.orgId
    }
  };

  const updateButton = createButton({
    actionId: "SECURITY_ROLE_UPDATE",
    id: "btn-save",
    text: save,
    disabled: formIsUntouchedOrHasError(formik),
    onPress: async () => {
      formik.handleSubmit();
      if (formik.isValid) {
        await update!(params).unwrap();
        closeWizard();
        navigate(`${routes.SECURITY_ROLES}/${userRoleId}`);
      }
    }
  });

  const deleteButton = createButton({
    id: "btn-delete",
    text: deactivate,
    color: "error",
    variant: "outlined",
    onPress: async () => {
      const sure = window.confirm(deleteConfirmation);
      if (sure) {
        await deleteFn!({ userRoleId });
        closeWizard();
        navigate(routes.SECURITY_ROLES, { replace: true });
      }
    },
    actionId: "SECURITY_ROLE_DELETE",
    separateButtonBlock: true
  });

  const cancelButton = createButton({
    actionId: "SECURITY_ROLE_UPDATE",
    id: "btn-cancel",
    text: cancel,
    color: "secondary",
    onPress: () => {
      closeWizard();
    },
    variant: "outlined"
  });

  return createButtonBoxPostAmble({
    permissions,
    buttonsMeta: [updateButton, cancelButton, deleteButton]
  });
};

export const additionalButtonsList: DC["additionalButtonsList"] = ({ permissions, wizardFunctions, data }) => [
  {
    allowed: userCan({ actionId: "SECURITY_ROLE_UPDATE", userPermissions: permissions }),
    color: "primary",
    title: edit,
    onClick: () => {
      wizardFunctions?.launchEditUserRoleWizard();
    }
  },
  {
    allowed: userCan({ actionId: "SECURITY_ROLE_COPY", userPermissions: permissions }),
    title: copy,
    variant: "outlined",
    onClick: () => {
      wizardFunctions?.launchCopyRoleWizard(data);
    }
  }
];
