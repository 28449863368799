/* eslint-disable */
import { format, parseISO } from "date-fns";
import { jwtDecode } from "jwt-decode";
import { initiateAuth } from "../aws";
import { commonStrings, appStyles, appConstants } from "../assets";
import * as yup from "yup";

const {
  appStrings: {
    notifications: { sessionExpired }
  }
} = commonStrings;

const { HTMLStyles } = appStyles;

const { HTMLElement } = appConstants;

const ElementTag = /(<([^>]+)>)/gi;
export const parseHTMLElements = (message: string) =>
  typeof message === "string" && message && message.length < 10000 ? message.replace(ElementTag, "") : "";

export const getIsDisabledField = (isDisabled: boolean | undefined, isReadonly: boolean | undefined): boolean => {
  return !!isDisabled && !isReadonly;
};

export const formattedDate = (value: string, formatPattern = "MM/dd/yyyy") => {
  const trimmed = value?.trim() ?? "";

  try {
    return format(parseISO(trimmed), formatPattern);
  } catch (error) {
    try {
      return format(new Date(trimmed), formatPattern);
    } catch (error) {
      return trimmed;
    }
  }
};

export const getEarliestBirthDate = () => {
  // const today = new Date();
  // return new Date(today.getFullYear() - 110, today.getMonth(), today.getDay());
  // changed according to SA-142
  return new Date("1/1/1900");
};

export const queryResultToUINeed = (prevConsents: any, data: any) => ({
  items: new Map(data.consents.map((consent: any) => [consent.consentId, consent])) as any,
  badgeCount: prevConsents.badgeCount ?? 0,
  lastFetchedTs: data.nextToken,
  sort: []
});

export const fieldValueEqual = (prevProps, nextProps) => {
  return (
    JSON.stringify(prevProps.needConfirmation) === JSON.stringify(nextProps.needConfirmation) &&
    prevProps.value === nextProps.value &&
    prevProps.error === nextProps.error &&
    prevProps.fieldInfo?.disabled === nextProps.fieldInfo?.disabled &&
    prevProps.fieldInfo?.readOnly === nextProps.fieldInfo?.readOnly &&
    JSON.stringify(prevProps.fieldInfo?.options) === JSON.stringify(nextProps.fieldInfo?.options)
  );
};

export const toMap = (sections) =>
  Object.fromEntries(
    sections.map(([sectionId, section]) => [sectionId, { ...section, fields: Object.fromEntries(section.fields) }])
  );

export const combineSchemaData = ({ schema, data }) => {
  const schemaSections: [string, any][] = JSON.parse(schema).sections;
  const dataSections: [string, any][] = JSON.parse(data).sections;
  const dataMap = toMap(dataSections);
  return schemaSections
    ?.filter(([_, section]) => section.fields)
    .map(([sectionName, section]) => ({
      ...section,
      sectionName,
      fields: section.fields.map(([fieldName, field]) => {
        return dataMap?.[sectionName]?.fields?.[fieldName]
          ? {
              name: fieldName,
              ...field,
              ...(dataMap?.[sectionName]?.fields?.[fieldName] ?? {})
            }
          : {
              name: fieldName,
              ...field
            };
      })
    }));
};

export const getAncestorIdsFromUrllike = (orgId: string, includeFinalOrgId = false) => {
  const sliceArgs = includeFinalOrgId ? [1] : [1, -1];
  return [
    "/",
    ...orgId
      .split("/")
      .slice(...sliceArgs)
      .reduce((acc: any[], _: string, idx: number, arr: any[]) => {
        const subArr = arr.slice(0, idx + 1);
        return [...acc, subArr.join("/")];
      }, [])
      .map((id) => `/${id}`)
  ];
};

export const listAncestorDirectories = (current, includeCurrent = false) => {
  const sliceArgs = includeCurrent ? [0] : [0, -1];
  return [
    "/",
    ...current
      .split("/")
      .filter(Boolean)
      .reduce((acc, e) => [...acc, acc[acc.length - 1] + "/" + e], [""])
      .filter(Boolean)
  ].slice(...sliceArgs);
};

export const filterKeysBy = (obj: any, fn: (obj: any) => (key: string) => any) => {
  const filterFunction = fn(obj);
  try {
    const processedObject = Object.fromEntries(Object.entries(obj).filter(([key, _]) => filterFunction(key)));
    return processedObject;
  } catch (e) {
    return obj;
  }
};

export const clearAllTokens = () => {
  window?.localStorage?.removeItem("midato:idToken");
  window?.localStorage?.removeItem("midato:accessToken");
  window?.localStorage?.removeItem("midato:refreshToken");
  window?.localStorage?.removeItem("midato:guest:idToken");
  window?.localStorage?.removeItem("midato:selectedOrganization");
};

export const setAllTokens = (tokens: {
  idToken?: string;
  accessToken?: string;
  refreshToken?: string;
  "guest:idToken"?: string;
}) => {
  Object.entries(tokens)
    .filter(([_, value]) => value)
    .forEach(([key, value]) => {
      window?.localStorage?.setItem(`midato:${key}`, value);
    });
};

export const getUseAuthDataFromToken = (token?: string) => {
  const rawAuth = token
    ? tokenToAuth<{
        given_name: string;
        family_name: string;
        org_id: string;
        user_id: string;
        permissions: string;
        org_ids: string;
        email: string;
      }>(token ?? "", ([key, value]) => {
        switch (key) {
          case "given_name":
            return ["firstName", value];
          case "family_name":
            return ["lastName", value];
          // case "org_id":
          //   return ["orgId", value];
          case "user_id":
            return ["userId", value];
          case "permissions":
            return ["permissions", value.split("|")];
          case "org_ids":
            return ["orgIds", value.split("|")];
          case "email":
            return ["email", value];
          default:
            return ["default", undefined];
        }
      })
    : null;

  const permissions = rawAuth?.permissions ?? [];
  // ? { ...rawAuth, orgId: window.localStorage.getItem("midato:selectedOrganization") || rawAuth?.orgId }
  const auth = permissions?.length > 0 ? rawAuth : undefined;
  const orgId = window.localStorage.getItem("midato:selectedOrganization") || "";
  if (orgId && auth) {
    auth.orgId = orgId;
  }

  return { auth, permissions };
};

export const tokenToAuth = <T,>(
  token: string,
  mapFunction: (value: [keyof T, any], index: number, array: [keyof T, any][]) => readonly [PropertyKey, any] = (
    value
  ) => value
): {
  firstName: string;
  lastName: string;
  orgId: string;
  userId: string;
  token: string;
  email: string;
  permissions: string[];
} => {
  try {
    const user = jwtDecode<T>(token);
    // @ts-ignore
    return {
      token,
      ...Object.fromEntries(
        (Object.entries(user) as [keyof T, any][]).map(mapFunction).filter(([key, value]) => key && value)
      )
    };
  } catch (error) {
    return undefined;
  }
};

export const httpErrorHandlerCreators = {
  401:
    <T,>({
      setAuth,
      closeSpinner,
      displayAnNotificationMessage,
      ClientId,
      tokenToAuthMapCallback
    }: {
      setAuth: Function;
      closeSpinner: Function;
      displayAnNotificationMessage: Function;
      ClientId: string;
      tokenToAuthMapCallback: (
        value: [keyof T, any],
        index: number,
        array: [keyof T, any][]
      ) => readonly [PropertyKey, any];
    }) =>
    async ({ refetch }: { refetch: Function }) => {
      try {
        const refreshToken = window?.localStorage?.getItem("midato:refreshToken");
        const inactivityTime = window.localStorage.getItem("midato:inactivityTime");

        if (!refreshToken) {
          closeSpinner();
          clearAllTokens();
          setAuth(null);
          clearAllTokens();
          window.location.replace("/login");
          throw new Error("no refreshToken");
        }

        // @ts-ignore
        if (inactivityTime && new Date() - new Date(inactivityTime) > 900000) {
          closeSpinner();
          clearAllTokens();
          setAuth(null);
          clearAllTokens();
          window.sessionStorage.setItem("midato:showInactivityTime", "true");
          window.location.replace("/login");
          throw new Error("user inactivity");
        }

        const { data, error } = await initiateAuth({
          AuthFlow: "REFRESH_TOKEN_AUTH",
          AuthParameters: { REFRESH_TOKEN: refreshToken },
          ClientId
        });

        if (error) {
          closeSpinner();
          clearAllTokens();
          setAuth(null);
          clearAllTokens();
          window.location.replace("/login");
          throw new Error("refreshToken is expired");
        }

        const {
          AuthenticationResult: { AccessToken, IdToken }
        } = data;

        const auth = tokenToAuth<T>(IdToken ?? "", tokenToAuthMapCallback);

        if (!auth) {
          closeSpinner();
          displayAnNotificationMessage("session is not valid");
          clearAllTokens();
          setAuth(null);
          clearAllTokens();
          window.location.replace("/login");
          throw new Error("token not valid");
        }

        setAllTokens({ accessToken: AccessToken, idToken: IdToken });
        setAuth(auth);
        refetch();
      } catch (error) {
        closeSpinner();
        displayAnNotificationMessage(sessionExpired);
        clearAllTokens();
        setAuth(null);
        clearAllTokens();
        window.location.replace("/login");
      }
    }
};

export const extractFieldLabel = (label: any, componentLabel: any) => {
  return componentLabel
    ? componentLabel
    : label && (
        <div
          dangerouslySetInnerHTML={{
            __html: HTMLStyles.switch + (HTMLElement.test(label) ? label : `<p>${label}</p>`)
          }}
        />
      );
};

export const createTokenToAuthMapCallback =
  <T,>(decodedTokenKeyMap: { [key in keyof T]: string }, parseFn?: Function) =>
  ([key, value]: [keyof T, any]): readonly [PropertyKey, any] => {
    return [decodedTokenKeyMap[key], typeof parseFn === "function" ? parseFn(key, value) : value];
  };

export const capitalizedString = (value: string) => {
  return value.charAt(0).toUpperCase() + value.slice(1);
};

export const makeDOMElementId = (value: string, prefix?: string) => {
  return `${prefix ? `${prefix}-` : ""}${value.replace(/\s+/g, "-").toLowerCase()}`;
};

export const downloadFile = async (url: string, fileName?: string) => {
  const file = await fetch(url);
  const fileBlob = await file.blob();
  const fileUrl = URL.createObjectURL(fileBlob);

  const anchor = document.createElement("a");
  anchor.href = fileUrl;

  anchor.download = fileName ? fileName : (url.split("?")?.[0]?.split("/")?.pop() ?? "");
  anchor.target = "_blank";
  document.body.appendChild(anchor);
  anchor.click();
  document.body.removeChild(anchor);
  URL.revokeObjectURL(fileUrl);
};

export const getValueThroughPath = (obj: object, path: string, defaultValue: any) =>
  String(path)
    .split(".")
    .reduce<any>((acc, v) => {
      try {
        acc = acc[v] ?? defaultValue;
      } catch (e) {
        return defaultValue;
      }
      return acc;
    }, obj);

export const toLowerCase = (str: string) => str.toLowerCase();

export const combineIds = (...ids: string[]) => ids.join("/");

export const phoneAndCountryCodeCombiner = (countryCode: string, phoneNumber: string) => {
  return phoneNumber && countryCode + phoneNumber.replace(/\D/g, "");
};

export const getPhoneNumWithoutCountryCode = (countryCode: string, phoneNumber: string) => {
  if (!phoneNumber) {
    return "";
  }
  return phoneNumber.trim() !== "" && phoneNumber.startsWith(`${countryCode}`) ? phoneNumber.substring(2) : phoneNumber;
};

const defaultErrorStrings = {
  requiredError: "This field is required",
  usernameRequiredError: "Username is required",
  phoneRequiredError: "Phone number is required",
  phoneNumInvalidError: "Invalid phone number",
  invalidPhoneError: "Country code required (ie +1)",
  phoneMaxLengthError: (max: number) => `Phone number must be less than ${max} numbers`,
  phoneMinLengthError: (min: number) => `Phone number must be at least ${min} digits`,
  textMaxLengthError: (max: number) => `Text must be less than ${max} characters`,
  textMinLengthError: (min: number) => `Text must be a least ${min} characters`,
  npiError: "NPI should be 9 digits",
  numberMax: (max: number) => `Value must be less than or equal to ${max}`,
  numberMin: (min: number) => `Value must be greater than or equal to ${min}`,
  passwordRequiredError: "Password is required",
  confirmationRequiredError: "Password confirmation is required",
  confirmationMismatchError: "Passwords don't match",
  emailRequiredError: "Email Address is required",
  invalidEmailError: "Invalid email address",
  invalidZipError: "Invalid Zip Code",
  minLengthError: "Password must be at least 8 characters",
  maxLengthError: "Password must be less than 17 characters",
  nameMaxLengthError: "Name must be less than 17 characters",
  invalidPasswordError: "Minimum 8 characters, at least 1 uppercase letter, 1 lowercase letter and 1 number",
  hasCapError: "Password must contain an uppercase letter",
  hasLowerError: "Password must contain an lowercase letter",
  hasDigitError: "Password must contain a number",
  hasSymbolError: "Password must contain at least one special character",
  unexpectedError: "Unexpected Error",
  agreementError: "Must accept agreement before continuing",
  dobMinYearError: "DOB must be later than 110 years ago",
  dobMaxYearError: "DOB must be earlier than today",
  dobRequiredError: "Date of birth is required",
  dobInvalidError: "Invalid date of birth",
  generalError: "some error happens",
  secretKeyError: "secret is not correct",
  linkExpiredError: "The link has expired",
  formExpiredNumInvalidError: "Please provide a number",
  dateMin: "Date should be after",
  invalidDateError: "Invalid date "
};

const {
  requiredError,
  phoneNumInvalidError,
  invalidPhoneError,
  phoneMaxLengthError,
  phoneMinLengthError,
  textMaxLengthError,
  textMinLengthError,
  numberMax,
  numberMin,
  invalidEmailError,
  invalidZipError,
  dobInvalidError,
  formExpiredNumInvalidError,
  invalidDateError
} = defaultErrorStrings;

const regex = {
  isValidEmail:
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  isValidPhone: /^\+[1-9]{1}[0-9]{3,14}$/,
  isValidAnyPhone: /^(?:\+\d{1,3})?\d{10}$/,
  isValidZip: /^\d{5}$/,
  isValidExpressZip: /(^\d{4}$)/,
  hasCap: /[A-Z]/,
  hasLower: /[a-z]/,
  hasDigit: /[[\d]/,
  hasSymbol: /[\W]/,
  isNumber: /^\d*$/,
  isValidUsPhone: /^[(]?[0-9]{3}[)]?[ ,-]?[0-9]{3}[ ,-]?[0-9]{4}$/
};

const { isValidUsPhone, isNumber, isValidExpressZip, isValidZip, isValidAnyPhone } = regex;

const baseFieldRules = yup
  .string()
  .matches(/^[^()*$]*$/, "Field cannot contain (, ), *, or $")
  .matches(/^[\w\s\d.,/\-\'!?]*$/, "Field can only contain letters, numbers, spaces, and punctuation")
  .test("sql-injection", "Field cannot contain SQL injection patterns", (value?: string) => {
    const sqlKeywords = ["SELECT", "INSERT", "UPDATE", "DELETE", "FROM", "WHERE", "DROP", "TABLE"];
    return !sqlKeywords.some((keyword) => value?.includes(keyword));
  });

const baseFieldRulesSearch = yup
  .string()
  .matches(/^[^()*$]*$/, "Field cannot contain (, ), *, or $")
  .matches(/^[\w\s\d.,/\-\']*$/, "Field can only contain letters, numbers, spaces, and punctuation")
  .test("sql-injection", "Field cannot contain SQL injection patterns", (value?: string) => {
    const sqlKeywords = ["SELECT", "INSERT", "UPDATE", "DELETE", "FROM", "WHERE", "DROP", "TABLE"];
    return !sqlKeywords.some((keyword) => value?.includes(keyword));
  });

export const validateSchemaString = (formSchemaString?: string) => {
  if (!formSchemaString) {
    return false;
  }
  try {
    const schema = JSON.parse(formSchemaString);
    return "sections" in schema;
  } catch (e) {
    return false;
  }
};

const baseFieldSearchRulesSearch = yup
  .string()
  .matches(/^[^()*$]*$/, "Field cannot contain (, ), *, or $")
  .matches(/^[\@\w\s\d.,/\-\']*$/, "Field can only contain letters, numbers, spaces, and punctuation")
  .test("sql-injection", "Field cannot contain SQL injection patterns", (value?: string) => {
    const sqlKeywords = ["SELECT", "INSERT", "UPDATE", "DELETE", "FROM", "WHERE", "DROP", "TABLE"];
    return !sqlKeywords.some((keyword) => value?.includes(keyword));
  });

export const validations = {
  date: yup.date().nullable().min("1/1/1900").typeError(invalidDateError),
  text: yup.string().nullable().concat(baseFieldRules),
  textMinMaxSearch: (min: number, max: number) =>
    yup
      .string()
      .concat(baseFieldSearchRulesSearch)
      .nullable()
      .min(min || 0, textMinLengthError(min || 0))
      .max(max || 150, textMaxLengthError(max || 150)),
  textMinMax: (min: number, max: number) =>
    yup
      .string()
      .concat(baseFieldRules)
      .nullable()
      .min(min || 0, textMinLengthError(min || 0))
      .max(max || 150, textMaxLengthError(max || 150)),
  textMinMaxCustom: (min: number, max: number, text: string) =>
    yup
      .string()
      .concat(baseFieldRules)
      .nullable()
      .min(min || 0, text)
      .max(max || 150, text),
  zip: yup.string().max(5).concat(baseFieldRules).nullable().matches(isValidZip, invalidZipError),
  file: yup.mixed(),
  array: yup.array(),
  expressZip: yup.string().concat(baseFieldRules).nullable().matches(isValidExpressZip, invalidZipError),
  number: yup.string().concat(baseFieldRules).matches(isNumber, formExpiredNumInvalidError),
  requiredNumber: yup
    .string()
    .concat(baseFieldRules)
    .required(requiredError)
    .matches(isNumber, formExpiredNumInvalidError),
  requiredNumberMinMax: (min: number, max: number) =>
    yup
      .number()
      .typeError(formExpiredNumInvalidError)
      .required(requiredError)
      .min(min || 1, numberMin(min || 1))
      .max(max || 9999999, numberMax(max || 9999999)),
  usPhone: yup.string().concat(baseFieldRules).matches(isValidUsPhone, invalidPhoneError),
  requiredUsPhone: yup
    .string()
    .concat(baseFieldRules)
    .required(requiredError)
    .matches(isValidUsPhone, invalidPhoneError),
  phone: yup
    .string()
    .nullable()
    .min(10, phoneMinLengthError(10))
    .max(16, phoneMaxLengthError(16))
    .matches(isValidAnyPhone, phoneNumInvalidError),
  requiredPhone: yup
    .string()
    .nullable()
    .min(10, phoneMinLengthError(10))
    .max(16, phoneMaxLengthError(16))
    .matches(isValidAnyPhone, phoneNumInvalidError)
    .required(requiredError),
  requiredText: yup.string().concat(baseFieldRules).nullable().required(requiredError),
  requiredTextMinMax: (min: number, max: number) =>
    yup
      .string()
      .concat(baseFieldRules)
      .nullable()
      .min(min || 1, textMinLengthError(min || 1))
      .max(max || 150, textMaxLengthError(max || 150))
      .required(requiredError),
  requiredDob: yup
    .date()
    .nullable()
    .min("1/1/1900")
    .max(format(new Date(), "MM/dd/yyyy"))
    .required(requiredError)
    .typeError(dobInvalidError),
  dob: yup.date().nullable().min("1/1/1900").max(format(new Date(), "MM/dd/yyyy")).typeError(dobInvalidError),
  requiredEmail: yup
    .string()
    .max(65, textMaxLengthError(65))
    .required(requiredError)
    .email(invalidEmailError)
    .matches(/^[^"']*$/, "Field cannot contain quotes")
    .matches(/^[ -~]+$/, "Field cannot contain unicode"),
  email: yup
    .string()
    .max(65, textMaxLengthError(65))
    .email(invalidEmailError)
    .matches(/^[^"']*$/, "Field cannot contain quotes")
    .matches(/^[ -~]+$/, "Field cannot contain unicode"),
  nonEmptyArray: yup.array().min(1).required(),
  requiredFile: yup.mixed().required(),
  validateSchema: yup.string().required().test("isValidSchema", "Not a valid schema", validateSchemaString),
  baseFieldRules: yup
    .string()
    .matches(/^[^()*$]*$/, "Field cannot contain (, ), *, or $")
    .matches(/^[\w\s\d.,!?]*$/, "Field can only contain letters, numbers, spaces, and punctuation")
    .test("sql-injection", "Field cannot contain SQL injection patterns", (value?: string) => {
      const sqlKeywords = ["SELECT", "INSERT", "UPDATE", "DELETE", "FROM", "WHERE", "DROP", "TABLE"];
      return !sqlKeywords.some((keyword) => value?.includes(keyword));
    })
};
