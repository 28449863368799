import { SortOrderType } from "@midato-mono/common/src";
import { graphql } from "@midato-mono/common/src/aws";

const GQL_ENDPOINT = import.meta.env.REACT_APP_ADMIN_GQL_ENDPOINT;

interface QueryConfig<T> {
  auth?: string;
  variables: T;
}

export interface AddPageLoadTimeConfig {
  userId: string;
  userEmail: string;
  orgId: string;
  pageName: string;
  timestamp: number;
  loadTime: string;
}

const addPageLoadTime = async ({ auth, variables }: QueryConfig<AddPageLoadTimeConfig>) => {
  return await graphql(
    GQL_ENDPOINT,
    `
      mutation ($event: PageLoadTimeEvent!) {
        addPageLoadTime(event: $event) {
          userId
        }
      }
    `,
    { event: variables },
    auth
  );
};

export interface LogUserEventConfig {
  type:
    | "login"
    | "loginFailed"
    | "navigation"
    | "consumerSearch"
    | "viewConsumer"
    | "consentSearch"
    | "viewConsent"
    | "userCreate"
    | "userEdit"
    | "ssoLaunch";
  params: unknown;
  ipAddress: string;
  browserType: string;
  route: string;
  consumerId?: string;
  apiInvoked?: string;
}

const logUserEvent = async ({ auth, variables }: QueryConfig<LogUserEventConfig>) => {
  return await graphql(
    GQL_ENDPOINT.replace("cms", "log"),
    `
      mutation ($event: UserActivityLogEvent!) {
        addUserActivityLog(event: $event) {
          ...userActivityLogFields
        }
      }
    `,
    { event: variables },
    auth
  );
};

export interface GetUserActivityLogsConfig {
  type:
    | "login"
    | "loginFailed"
    | "navigation"
    | "consumerSearch"
    | "viewConsumer"
    | "consentSearch"
    | "viewConsent"
    | "userCreate"
    | "userEdit"
    | "ssoLaunch";
  params: unknown;
  ipAddress: string;
  browserType: string;
  route: string;
  consumerId?: string;
  apiInvoked?: string;
}

const getUserActivityLogs = async ({ auth, variables }: QueryConfig<GetUserActivityLogsConfig>) => {
  return await graphql(
    GQL_ENDPOINT,
    `
      query ($params: UserActivityLogParams!, $limit: Int, $page: Int, $sortBy: UserActivityLogSort) {
        getUserActivityLogs(params: $params, limit: $limit, page: $page, sortBy: $sortBy) {
          results {
            ...userActivityLogFields
          }
          total
          page
          limit
        }
      }
    `,
    variables,
    auth
  );
};

const getUserActivityLogsExport = async ({ auth, variables }: QueryConfig<GetUserActivityLogsConfig>) => {
  return await graphql(
    GQL_ENDPOINT,
    `
      query ($params: UserActivityLogParams!, $sortBy: UserActivityLogSort) {
        generateUserActivityLogReport(params: $params, sortBy: $sortBy)
      }
    `,
    variables,
    auth
  );
};

export interface GetConsentStatisticsReportConfig {
  params: unknown;
  limit: number;
  page: number;
  sortBy: string;
}

const getConsentStatisticsReport = async ({ auth, variables }: QueryConfig<GetConsentStatisticsReportConfig>) => {
  return await graphql(
    GQL_ENDPOINT,
    `
      query ($params: ConsentStatisticsReportParams!, $limit: Int, $page: Int, $sortBy: ConsentStatisticsReportSort) {
        getConsentStatistics(params: $params, limit: $limit, page: $page, sortBy: $sortBy) {
          results {
            organizationName
            isDde
            isOcr
            total
          }
          total
        }
      }
    `,
    variables,
    auth
  );
};

export interface GetConsentExpiryReportConfig {
  type:
    | "login"
    | "loginFailed"
    | "navigation"
    | "consumerSearch"
    | "viewConsumer"
    | "consentSearch"
    | "viewConsent"
    | "userCreate"
    | "userEdit"
    | "ssoLaunch";
  params: unknown;
  ipAddress: string;
  browserType: string;
  route: string;
  consumerId?: string;
  apiInvoked?: string;
}

const getConsentExpiryReport = async ({ auth, variables }: QueryConfig<GetConsentExpiryReportConfig>) => {
  return await graphql(
    GQL_ENDPOINT,
    `
      query ($params: ExpiringReportParams!, $limit: Int, $page: Int, $sortBy: ConsentReportSort) {
        getExpiringReport(params: $params, limit: $limit, page: $page, sortBy: $sortBy) {
          total
          page
          limit
          results {
            consentId
            creatingOrganization {
              orgId
              name
            }
            recipientOrganizations {
              orgId
              name
            }
            disclosingOrganizations {
              orgId
              name
            }
            consumer {
              name
              email
              consumerId
              firstName
              lastName
            }
            createdAt
            expireAt
            signedAt
            startAt
            status
            bidirectionalSharing
            title
            tpo
          }
        }
      }
    `,
    variables,
    auth
  );
};

const getConsentExpiryReportExport = async ({ auth, variables }: QueryConfig<GetUserActivityLogsConfig>) => {
  return await graphql(
    GQL_ENDPOINT,
    `
      query ($params: ExpiringReportParams!, $sortBy: ConsentReportSort) {
        generateExpiringReport(params: $params, sortBy: $sortBy)
      }
    `,
    variables,
    auth
  );
};

export interface GetConsentReportConfig {
  type:
    | "login"
    | "loginFailed"
    | "navigation"
    | "consumerSearch"
    | "viewConsumer"
    | "consentSearch"
    | "viewConsent"
    | "userCreate"
    | "userEdit"
    | "ssoLaunch";
  params: unknown;
  ipAddress: string;
  browserType: string;
  route: string;
  consumerId?: string;
  apiInvoked?: string;
}

const getConsentReport = async ({ auth, variables }: QueryConfig<GetUserActivityLogsConfig>) => {
  return await graphql(
    GQL_ENDPOINT,
    `
      query ($params: ConsentReportParams!, $limit: Int, $page: Int, $sortBy: ConsentReportSort) {
        getConsentReport(params: $params, limit: $limit, page: $page, sortBy: $sortBy) {
          results {
            bidirectionalSharing
            consentId
            creatingOrganization {
              orgId
              name
            }
            recipientOrganizations {
              name
            }
            disclosingOrganizations {
              name
            }
            consumer {
              name
              email
              consumerId
              firstName
              lastName
            }
            title
            createdAt
            expireAt
            signedAt
            startAt
            status
            tpo
          }
          total
          page
          limit
        }
      }
    `,
    variables,
    auth
  );
};

export interface GetUsageReportConfig {
  params: {
    orgId: string;
    firstName?: string;
    lastName?: string;
    email?: string;
    startDate?: string;
    endDate?: string;
    orgIds?: string[];
  };
  sortBy?: {
    organizationName: SortOrderType;
    login: SortOrderType;
    loginFailed: SortOrderType;
    userCreate: SortOrderType;
    uniqueUsersCount: SortOrderType;
  };
}

const getUsageReport = async ({ auth, variables }: QueryConfig<GetUserActivityLogsConfig>) => {
  return await graphql(
    GQL_ENDPOINT,
    `
      query ($params: SystemUsageReportParams!, $limit: Int, $page: Int, $sortBy: SystemUsageReportSort) {
        getSystemUsageReport(params: $params, limit: $limit, page: $page, sortBy: $sortBy) {
          results {
            organizationName
            login
            loginFailed
            userCreate
            uniqueUsersCount
          }
          total
        }
      }
    `,
    variables,
    auth
  );
};

const getUsageReportExport = async ({ auth, variables }: QueryConfig<GetUserActivityLogsConfig>) => {
  return await graphql(
    GQL_ENDPOINT,
    `
      query generateSystemUsageReport($params: SystemUsageReportParams!, $sortBy: SystemUsageReportSort) {
        generateSystemUsageReport(params: $params, sortBy: $sortBy)
      }
    `,
    variables,
    auth
  );
};

export interface GetLoadTimeReportConfig {
  userId?: string;
  // user: PageLoadTimeParamsUser;
  // organization: PageLoadTimeParamsOrganization;
  orgId?: string;
  pageName?: string;
  startDate?: string;
  endDate?: string;
  orgIds?: string[];
}

const getLoadTimeReport = async ({ auth, variables }: QueryConfig<GetLoadTimeReportConfig>) => {
  return await graphql(
    GQL_ENDPOINT,
    `
      query getPageLoadTimes($params: PageLoadTimeParams!, $limit: Int, $page: Int, $sortBy: PageLoadTimeSort) {
        getPageLoadTimes(params: $params, limit: $limit, page: $page, sortBy: $sortBy) {
          total
          page
          limit
          results {
            pageLoadTimeId
            userId
            userEmail
            user {
              firstName
              lastName
            }
            orgId
            organization {
              name
            }
            pageName
            timestamp
            loadTime
          }
        }
      }
    `,
    variables,
    auth
  );
};

export interface GetLoadTimeSummaryReportConfig {
  // TODO remove
  test?: string;
}

const getLoadTimeSummaryReport = async ({ auth, variables }: QueryConfig<GetLoadTimeSummaryReportConfig>) => {
  return await graphql(
    GQL_ENDPOINT,
    `
      query getPageLoadTimeReportSummary {
        getPageLoadTimeReportSummary {
          pageName
          averagePageLoadTime
          maximumPageLoadTime
        }
      }
    `,
    variables,
    auth
  );
};

const getConsentReportExport = async ({ auth, variables }: QueryConfig<GetUserActivityLogsConfig>) => {
  return await graphql(
    GQL_ENDPOINT,
    `
      query ($params: ConsentReportParams!, $sortBy: ConsentReportSort) {
        generateConsentReport(params: $params, sortBy: $sortBy)
      }
    `,
    variables,
    auth
  );
};

const getConsentStatisticsReportExport = async ({ auth, variables }: QueryConfig<GetConsentStatisticsReportConfig>) => {
  return await graphql(
    GQL_ENDPOINT,
    `
      query ($params: ConsentStatisticsReportParams!, $sortBy: ConsentStatisticsReportSort) {
        getConsentCsvStatistics(params: $params, sortBy: $sortBy)
      }
    `,
    variables,
    auth
  );
};

// TODO move reports to separate service
export {
  addPageLoadTime,
  getConsentStatisticsReportExport,
  getConsentStatisticsReport,
  getUsageReport,
  getUsageReportExport,
  logUserEvent,
  getUserActivityLogs,
  getUserActivityLogsExport,
  getConsentReport,
  getConsentReportExport,
  getConsentExpiryReport,
  getConsentExpiryReportExport,
  getLoadTimeReport,
  getLoadTimeSummaryReport
};
