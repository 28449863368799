import { useEffect, lazy } from "react";
import { ConsumerItemPageConfiguration } from "./types";
import assets from "../../../ui/assets";
import { createButton, createButtonBoxPostAmble, createAction, formIsUntouchedOrHasError } from "../../utils";
import {
  useAddNewConsumerMutation,
  useDeleteConsumerMutation,
  useFetchConsumerQuery,
  useResetConsumerMutation,
  useSearchItemsQuery,
  useUpdateConsumerMutation
} from "../../redux/api";
import { useMidatoQuery } from "../../hooks";
import fields from "./fields";
import { routes } from "../../../Router/constants";
import { userCan } from "../../utils";
import { useAuth } from "@midato-mono/common/src";
import { format, parse } from "date-fns";

const ConsumerConsentList = lazy(() => import("../../../ui/components/consumerDetail/ConsumerConsentList"));
const AddEditForm = lazy(() => import("../../../ui/components/common/form/AddEditForm"));

const {
  appStrings: {
    common: {
      appStrings: {
        buttonText: { save, cancel, deactivate, uploadConsent, createConsent, editConsumer },
        countryCodes,
        prompts: { deactivateConfirmation, goBackConfirmation }
      }
    },
    admin: {
      consumer: {
        item: { tabs, actionMenuOptions }
      }
    }
  }
} = assets;

// new
type NC = ConsumerItemPageConfiguration["new"];

export const createNewActions: NC["createActions"] = ({ toaster }) => ({
  add: createAction({ toaster, mutationFn: useAddNewConsumerMutation, type: "add", hideNotification: true })
});

export const createNewEntityName: NC["createEntityName"] = ({
  formik: {
    values: { firstName, lastName }
  }
}) => (firstName || lastName ? `${firstName} ${lastName}` : "New");

export const useNewInitialQuery: NC["useInitialQuery"] = () => {
  const { orgId } = useAuth();
  const { data: organizations } = useMidatoQuery({
    queryFn: useSearchItemsQuery,
    queryArgs: {
      params: {
        type: "organization",
        limit: 100000,
        query: "*",
        orgId
      }
    }
  });
  return { data: { organizations: organizations?.results } };
};

export const createNewRows: NC["createRows"] = ({ initialQueryData }) => {
  const activeOrganisationId: string = window.localStorage.getItem("midato:selectedOrganization") || "";
  const orgNamesMap = initialQueryData?.organizations?.reduce(
    (acc: { [key: string]: string }, { orgId, name }: { orgId: string; name: string }) => {
      acc[orgId] = name;
      return acc;
    },
    {}
  );
  return [
    [fields.personalHeader],
    [fields.firstName, fields.lastName],
    [fields.dob],
    [fields.gender],
    [fields.medicaidId],
    [
      {
        ...fields.organization,
        additionLabel: "Organization",
        value: orgNamesMap ? orgNamesMap?.[activeOrganisationId] : ""
      }
    ]
    // [fields.externalIdsHeader],
    // [
    //   {
    //     ...fields.externalIdHeader,
    //     value: orgNamesMap ? orgNamesMap[activeOrganisationId] : ""
    //   },
    //   fields.externalId
    // ]
  ];
};

export const createNewPostAmble: NC["createPostAmble"] = ({
  formik,
  actions: { add },
  navigate,
  permissions,
  closeWizard
}) => {
  const saveButton = createButton({
    id: "btn-save",
    text: save,
    actionId: "CONSUMER_ADD",
    onPress: async () => {
      const values = formik.values;
      formik.handleSubmit();
      if (formik.isValid) {
        const orgId = window.localStorage.getItem("midato:selectedOrganization");
        const params = {
          consumer: {
            medicaidId: values.medicaidId,
            address: {
              address1: values.address1,
              address2: values.address2,
              city: values.city,
              state: values.state,
              zip: values.zip
            },
            email: values.email || null,
            firstName: values.firstName,
            lastName: values.lastName,
            // phoneNumber: phoneAndCountryCodeCombiner(countryCodes.us, values.phoneNumber),
            phoneNumber: values.phoneNumber,
            dob: values.dob ? format(new Date(values.dob), "yyyy-MM-dd") : "",
            gender: values.gender,
            landlineNumber: values.landlineNumber,
            middleName: values.middleName,
            suffixName: values.suffixName,
            orgId,
            ...(values.externalId
              ? {
                  externalIds: [
                    {
                      orgId: orgId,
                      externalId: values.externalId || ""
                    }
                  ]
                }
              : {})
          }
        };
        const consumerId = await add!(params).unwrap();
        closeWizard();
        navigate(`${routes.CONSUMERS}/${consumerId}`);
      }
    },
    disabled: formIsUntouchedOrHasError(formik)
  });
  const cancelButton = createButton({
    actionId: "CONSUMER_ADD",
    id: "btn-cancel",
    text: cancel,
    color: "secondary",
    onPress: () => {
      const sure = formik.dirty ? window.confirm(goBackConfirmation) : true;
      if (sure) {
        closeWizard();
      }
    },
    variant: "outlined"
  });

  return createButtonBoxPostAmble({
    permissions,
    buttonsMeta: [saveButton, cancelButton]
  });
};

// detail
type DC = ConsumerItemPageConfiguration["detail"];

export const createDetailActions: DC["createActions"] = ({ toaster }) => ({
  update: createAction({ toaster, mutationFn: useUpdateConsumerMutation, type: "update" }),
  deactivate: createAction({ toaster, mutationFn: useDeleteConsumerMutation, type: "deactivate" }),
  resetConsumer: createAction({ toaster, mutationFn: useResetConsumerMutation, type: "resetConsumer" })
});

export const useDetailInitialQuery: DC["useInitialQuery"] = ({ params: { id: consumerId } }) => {
  const { orgId } = useAuth();
  const { data: organizations } = useMidatoQuery({
    queryFn: useSearchItemsQuery,
    queryArgs: {
      params: {
        type: "organization",
        limit: 100000,
        query: "*",
        orgId
      }
    }
  });

  const { data: consumerData } = useMidatoQuery({
    queryFn: useFetchConsumerQuery,
    queryArgs: {
      consumerId
    },
    queryOptions: { skip: !consumerId }
  });

  return { data: { organizations: organizations?.results, consumerData } };
};

export const createDetailActionMenuMeta: DC["createActionMenuMeta"] = ({
  wizardFunctions: {
    launchInviteConsumerWizard,
    launchSendConsentsWizard,
    // launchSendConsentsToGuestWizard,
    launchCreateConsentWizard
  },
  initialQueryData: data
  // navigate
}) => [
  {
    label: actionMenuOptions.sendAConsentForm,
    callback: () => {
      launchSendConsentsWizard(data.consumerData.consumerId, data.consumerData.firstName, data.consumerData.lastName);
    },
    hidden: false,
    actionId: "CONSENT_OBJECT_REQUEST"
  },
  {
    label: actionMenuOptions.createConsent,
    callback: () => {
      launchCreateConsentWizard(data.consumerData.consumerId, data.consumerData.firstName, data.consumerData.lastName);
    },
    hidden: false,
    bottomDivider: true,
    actionId: "CONSENT_ADD"
  },
  {
    label: actionMenuOptions.sendAppInvite,
    callback: () => {
      launchInviteConsumerWizard();
    },
    hidden: false,
    actionId: "CONSUMER_USER_INVITE"
  },
  {
    label: actionMenuOptions.sendPasswordReset,
    callback: () => {
      console.log(actionMenuOptions.sendPasswordReset);
    },
    hidden: false,
    actionId: "PASSWORD_RESET_INVITE"
  }
];

export const createNewDocumentTitle: DC["createDocumentTitle"] = ({ initialQueryData: { consumerData: consumer } }) =>
  `${consumer?.firstName} ${consumer?.lastName}`;

export const createDetailEntityName: DC["createEntityName"] = ({ initialQueryData: { consumerData: consumer } }) =>
  `${consumer?.firstName || ""} ${consumer?.lastName || ""}`;

export const createDetailInitialValues: DC["createInitialValues"] = ({
  initialQueryData: { consumerData: consumer }
}) => {
  const externalIdsValues = consumer?.externalIds.reduce(
    (acc: { [key: string]: string }, { orgId, externalId }: { orgId: string; externalId: string }) => {
      acc[`externalId_${orgId}`] = externalId;
      return acc;
    },
    {}
  );

  return {
    medicaidId: consumer?.medicaidId ?? "",
    consumerId: consumer?.consumerId ?? "",
    firstName: consumer?.firstName ?? "",
    lastName: consumer?.lastName ?? "",
    middleName: consumer?.middleName ?? "",
    suffixName: consumer?.suffixName ?? "",
    gender: consumer?.gender ?? undefined,
    dob: consumer?.dob ? format(parse(consumer?.dob, "yyyy-MM-dd", new Date()), "MM/dd/yyyy") : null,
    email: consumer?.email ?? "",
    // phoneNumber: getPhoneNumWithoutCountryCode(countryCodes.us, consumer?.phoneNumber),
    phoneNumber: consumer?.phoneNumber ?? "",
    landlineNumber: consumer?.landlineNumber ?? "",
    address1: consumer?.address?.address1 ?? "",
    address2: consumer?.address?.address2 ?? "",
    city: consumer?.address?.city ?? "",
    state: consumer?.address?.state ?? "",
    zip: consumer?.address?.zip ?? "",
    expressZip: consumer?.address?.expressZip ?? "",
    orgId: consumer?.orgId ?? "",
    ...externalIdsValues
  };
};

// @ts-ignore
export const createDetailRows: DC["createRows"] = ({
  initialQueryData,
  customStates,
  setCustomStates
}: {
  initialQueryData: { [key: string]: any };
  customStates: { editMode: boolean; additionalExternalIdFields: { [key: string]: unknown } };
  setCustomStates: Function;
}) => {
  const { organizations, consumerData } = initialQueryData || {};
  const activeOrganisationId: string = window.localStorage.getItem("midato:selectedOrganization") || "";
  const orgNamesMap = organizations?.reduce(
    (acc: { [key: string]: string }, { orgId, name }: { orgId: string; name: string }) => {
      acc[orgId] = name;
      return acc;
    },
    {}
  );

  const additionalExternalIdFields = customStates?.additionalExternalIdFields || {};
  const isSameOrg = consumerData?.orgId === activeOrganisationId;
  const showPlusButton =
    customStates?.editMode &&
    consumerData?.externalIds?.filter(({ orgId }: { orgId: string }) => orgId === activeOrganisationId)?.length < 1 &&
    Object.values(additionalExternalIdFields)?.length < 1;

  const onRemove = (randomId: number) => () => {
    const externalFields = { ...customStates?.additionalExternalIdFields };
    delete externalFields[randomId];
    setCustomStates({ ...customStates, additionalExternalIdFields: externalFields });
  };

  const externalIdsFields = consumerData?.externalIds?.length
    ? consumerData?.externalIds.map(({ orgId }: { orgId: string }) => [
        {
          ...fields.externalIdHeader,
          value: orgNamesMap?.[orgId]
        },
        { ...fields.externalId, name: `externalId_${orgId}`, disabled: orgId !== activeOrganisationId }
      ])
    : [
        [
          // {
          //   ...fields.externalIdHeader,
          //   value: orgNamesMap?.[consumerData?.orgId]
          // }
          // {
          //   ...fields.externalId,
          //   name: `externalId_custom_new`,
          //   disabled: consumerData?.orgId !== activeOrganisationId
          // }
        ]
      ];

  const onClick = () => {
    const randomId = Math.floor(Math.random() * 100);
    const externalFields = {
      ...additionalExternalIdFields,
      [randomId]: [
        {
          ...fields.externalIdHeader,
          label: orgNamesMap?.[activeOrganisationId],
          value: orgNamesMap?.[activeOrganisationId]
        },
        { ...fields.externalId, name: `externalId_custom` },
        { ...fields.removeExternalId, onPress: onRemove(randomId) }
      ]
    };
    setCustomStates({ ...customStates, additionalExternalIdFields: externalFields });
  };

  return [
    [fields.personalHeader],
    [fields.firstName, fields.middleName],
    [fields.lastName, fields.suffixName],
    [fields.dob],
    [fields.gender],
    [fields.medicaidId],
    [{ ...fields.organization, additionLabel: "Organization", value: orgNamesMap?.[consumerData?.orgId] || "" }],
    [fields.consumerId],
    ...(showPlusButton
      ? [[fields.externalIdsHeader, { ...fields.addExternalId, onPress: onClick }]]
      : [[fields.externalIdsHeader]]),
    ...externalIdsFields,
    ...Object.values(customStates?.additionalExternalIdFields),
    [fields.consumerId],
    [fields.contactHeader],
    [fields.phoneNumber, fields.landlineNumber],
    [fields.email],
    [fields.address1],
    [fields.address2],
    [fields.state, fields.city],
    [fields.zip]
  ];
};

export const createDetailPostAmble: DC["createPostAmble"] = ({
  formik,
  params: { id: consumerId },
  actions: { update, deactivate: deactivateFn },
  navigate,
  permissions,
  closeWizard
}) => {
  const selectedOrgId = window.localStorage.getItem("midato:selectedOrganization");
  const values = formik.values;

  const externalIdValues = Object.keys(values).filter((key) => key.includes("externalId"));
  const externalIds = externalIdValues.map((key) => {
    if (key === "externalId_custom") {
      return { orgId: selectedOrgId, externalId: values[key] };
    } else if (key === "externalId_custom_new") {
      return {
        orgId: selectedOrgId,
        externalId: values[key] || values[`externalId_${selectedOrgId}`]
      };
    } else {
      // @ts-ignore
      return { orgId: key.replace("externalId_", ""), externalId: values[key] };
    }
  });

  const updateButton = createButton({
    actionId: "CONSUMER_UPDATE",
    id: "btn-save",
    text: save,
    disabled: formIsUntouchedOrHasError(formik),
    onPress: async () => {
      formik.handleSubmit();
      const params = {
        consumerId,
        consumer: {
          address: {
            address1: values.address1,
            address2: values.address2,
            city: values.city,
            state: values.state,
            zip: values.zip
          },
          medicaidId: values.medicaidId,
          email: values.email || null,
          firstName: values.firstName,
          lastName: values.lastName,
          // phoneNumber: phoneAndCountryCodeCombiner(countryCodes.us, values.phoneNumber),
          phoneNumber: values.phoneNumber,
          dob: values.dob ? format(new Date(values.dob), "yyyy-MM-dd") : "",
          gender: values.gender,
          landlineNumber: values.landlineNumber,
          middleName: values.middleName,
          suffixName: values.suffixName,
          ...(externalIds?.length ? { externalIds } : {})
        }
      };
      if (formik.isValid) {
        const data = await update!(params);
        if (!data?.error) {
          closeWizard();
          navigate(`${routes.CONSUMERS}/${consumerId}`);
        }
      }
    }
  });

  const cancelButton = createButton({
    actionId: "CONSUMER_UPDATE",
    id: "btn-cancel",
    text: cancel,
    color: "secondary",
    onPress: () => {
      const sure = formik.dirty ? window.confirm(goBackConfirmation) : true;
      if (sure) {
        closeWizard();
      }
    },
    variant: "outlined"
  });

  const deleteButton = createButton({
    actionId: "CONSUMER_DELETE",
    id: "btn-delete",
    text: deactivate,
    color: "error",
    onPress: async () => {
      const sure = window.confirm(
        "Deactivating a Consumer should be used when a consumer record has been created in error.  This could be due to incorrect information being entered, a consumer deciding against the need for sharing data or other reasons.\n" +
          "\n" +
          "NOTE: When a Consumer is deactivated in the system, that applies to ALL organizations."
      );
      if (sure) {
        await deactivateFn!({
          consumerId
        });
        closeWizard();
        navigate(routes.CONSUMERS, { replace: true });
      }
    },
    variant: "outlined",
    separateButtonBlock: true
  });

  return createButtonBoxPostAmble({
    permissions,
    buttonsMeta: [updateButton, cancelButton, deleteButton]
  });
};

export const createDetailTabs: DC["createTabs"] = ({
  formik,
  rows,
  postAmbleInfo: postAmble,
  initialQueryData: { consumerData: consumer }
}) => {
  return [
    {
      label: { text: tabs.profile, disabled: false },
      TabComponent: AddEditForm,
      props: { formik, itemType: "consumer-detail", rows, postAmble, isViewMode: true }
    },
    {
      label: { text: tabs.consents, disabled: false },
      TabComponent: ConsumerConsentList,
      props: { consumerId: consumer?.consumerId }
    }
  ];
};

export const createDetailInitialTab: DC["createInitialTab"] = ({ searchParams }) => {
  const tab = searchParams.get("tab");
  return tab === "profile" || tab === "consents" ? tab : "profile";
};

export const useDetailEffects: DC["useEffects"] = ({ setSearchParams, currentTab }) => {
  useEffect(() => {
    currentTab && setSearchParams({ tab: currentTab }, { replace: true });
  }, [currentTab]);
};

export const additionalButtonsList: DC["additionalButtonsList"] = ({ permissions, wizardFunctions, data }) => [
  {
    allowed: userCan({ actionId: "CONSUMER_UPDATE", userPermissions: permissions }),
    color: "primary",
    title: editConsumer,
    onClick: () => {
      wizardFunctions?.launchEditConsumerWizard();
    }
  },
  {
    allowed: userCan({ actionId: "CONSENT_ADD", userPermissions: permissions }),
    title: createConsent,
    variant: "outlined",
    onClick: () => {
      wizardFunctions?.launchCreateConsentWizard(data?.consumerId, data?.firstName, data?.lastName);
    }
  },
  {
    allowed: userCan({ actionId: "CONSENT_UPLOAD", userPermissions: permissions }),
    title: uploadConsent,
    variant: "outlined",
    onClick: () => {
      wizardFunctions?.launchUploadConsentWizard({ consumerId: data?.consumerId, uploadType: "consumer" });
    }
  }
];
