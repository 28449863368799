import AdminLayout from "../components/common/layouts/AdminLayout";
import { useMidatoQuery, useWizard } from "../../app/hooks";
import { useFetchCurrentUserQuery } from "../../app/redux/api";
import { useAuth } from "@midato-mono/common/src";
import React, { Suspense, useEffect } from "react";
import SpinnerOverlay from "@midato-mono/common/src/components/SpinnerOverlay";
import ActionModal from "../components/common/ActionModal";
import TrackUserActivity from "../components/TrackUserActivity";
import ErrorBoundary from "@midato-mono/common/src/components/ErrorBoundary";
import { Outlet, useLocation } from "react-router-dom";
import { routes } from "../../Router/constants";
import Spinner from "@midato-mono/common/src/components/Spinner";
import DashView from "@midato-mono/common/src/components/DashView";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";

const Layout = () => {
  const { isPolicyAccepted, userId, setAuth } = useAuth();
  const { wizardFunctions } = useWizard();
  const { isLoading, data: userData } = useMidatoQuery({
    queryFn: useFetchCurrentUserQuery,
    queryArgs: {
      userId
    }
  });
  const location = useLocation();

  const withAdminLayout = !location.pathname.includes(routes.USER_GUIDE);

  const {
    closeWizard,
    wizardState: { activeWizard }
  } = useWizard();

  useEffect(() => {
    if (userData) {
      const { email, firstName, lastName, isPolicyAccepted = false } = userData;
      setAuth({
        email,
        firstName,
        lastName,
        isPolicyAccepted
      });

      if (!isPolicyAccepted) {
        wizardFunctions.launchLegalPoliciesWizard();
      }
    }
  }, [userData]);

  useEffect(() => {
    const handlePopState = () => {
      activeWizard && closeWizard();
    };
    window.addEventListener("popstate", handlePopState);
    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [activeWizard]);

  return (
    <ErrorBoundary>
      <TrackUserActivity />
      <SpinnerOverlay />
      <ActionModal />
      {withAdminLayout ? (
        isLoading || !isPolicyAccepted ? null : (
          <AdminLayout />
        )
      ) : (
        <Suspense fallback={<Spinner />}>
          <DashView>
            <Outlet />
          </DashView>
        </Suspense>
      )}
    </ErrorBoundary>
  );
};

export default Layout;
